// Container
// -----------------------------------------------------------------------------
.container-fluid {
  max-width: 1400px;
}

.container {

  .container {

    @include breakpoint(md) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
