.view-leadership {
  .view-header {
    margin-bottom: 5rem;

    h4 {
      color: #3f3f3f;
      font-weight: 600;
      font-size: 19px;
    }

    @include breakpoint(md) {
      margin-bottom: 8rem;
    }
  }

  .views-row {
    min-height: 300px;
    text-align: center;
  }

  .field-profile-image {
    margin-bottom: 10px;
  }

  .views-field-title {
    font-weight: 400;

    a {
      &:hover {
        border-bottom-color: #0074ce;
        border-bottom: 1px dotted ;
        opacity: .6;
        outline: 0;
        text-decoration: none;
      }
    }
  }

  .field-profile-title {
    color: #999;
    font-weight: 500;
    padding-bottom: 13px;
  }
}
