// quotes view
.block-views-internship-quotes-block,
.block-views-internship-quotes-block-1,
.block-views-internship-quotes-block-2, {
  h2 {
    font-weight: 100;
    margin-bottom: 50px;
    text-align: center;
  }
}

div[aria-hidden="true"] {
  .field-image {
    background: #000;

    img {
      opacity: 0.5;
    }
  }
}

.slick-active,
.slick-current {
  .field-image {
    img {
      opacity: 1;
    }
  }
}

.view-internship-quotes {
  .views-row {
    overflow: hidden;
  }

  .slick-arrow {
    color: #82BB27;
    font-size: 0;
    position: absolute;
    z-index: 99;

    &:focus {
      border-color: transparent;
      box-shadow: none;
      outline-style: none;
    }
  }

  .slick-next,
  .slick-prev {
    height: unset !important;
    top: 18%;
    width: unset !important;

    @include breakpoint(md) {
      top: 42%;
    }
  }


  .slick-next {
    right: -10px;

    @include breakpoint(lg) {
      right: 65px;
    }

    &:before {
      content: url('../images/arrow-right.svg');
    }
  }

  .slick-prev {
    left: -8px;
    @include breakpoint(lg) {
      left: 0;
    }

    &:before {
      content: url('../images/arrow-left.svg');
    }
  }

  .slick-dots {
    bottom: -75px;

    li {
      background-color: #fff;
      border: 2px solid #979797;

      &.slick-active {
        background-color: #82bb27;
        border: 2px solid #82bb27;
      }

      &:before {
        border: none;
      }
    }
  }
}
