.block-block-our-offices-span-three-main-regions {
  h2 {
    font-weight: 100;
  }

  margin-bottom: 6rem;

  .content {
    text-align: center;
  }
}

.block-bean-we-are-hiring-in-these-job-categ {
  .field-block-card {
    display: flex;
  }
}
