// Block: Footer
// -----------------------------------------------------------------------------
.block-panels-mini-footer {
  text-align: center;
  
  @include breakpoint(md) {
    text-align: left;
  }

  .copyright-pane {
    position: static;
    margin-top: 2rem;

    p {
      font-size: 1.3rem;
      line-height: 29px;
    }
  }

  .social-pane {
    margin-top: 3rem;

    .pane-content {

      a {
        display: inline-block;
        background-color: color(white);
        width: 30px;
        height: 30px;
        color: color(blue-link);
        border-radius: 50%;
        text-align: center;
        line-height: 30px;

        &:hover {
          background-color: color(green-link);
          color: color(white);
        }

        + a {
          margin-left: 5px;
        }

        i {
          display: inline-block;
          font-size: 1.8rem;
          margin-left: 5px;
        }
      }
    }
  }
}
