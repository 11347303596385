// Breadcrumb
// -----------------------------------------------------------------------------
.breadcrumb {
  display: none;
  line-height: 50px;
  max-width: 700px;
  
  @include breakpoint(md) {
    display: block;
  }

  a {
    color: color(blue-link);
    font-weight: 500;
  }

  i {
    color: color(blue-link);
    margin: 0 .5rem;
    display: inline-block;
  }
}
