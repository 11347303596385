.node-type-profile {
  .row {
    display: flex;
    flex-direction: column;

    .profile-content {
      order: 2;
    }

    .sidebar-right {
      order: 1;
    }

    @include breakpoint(md) {
      display: block;
    }
  }

  h1 {
    margin-top: 15px;
    margin-bottom: 12.5px;
  }

  .profile-title {
    color: #0074ce;
    font-weight: normal;
    font-size: 23px;
    margin-bottom: 40px;
  }

  .sidebar-right {
    @include breakpoint(md) {
      width: 24.774%;
      padding-left: 0;
      margin-left: 8.246%;
    }
  }

  .profile-image {
    margin-bottom: 25px;
  }

  .margin-sm-bottom {
    margin-bottom: 10px;
  }
}
