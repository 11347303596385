.region__footer-prefix {
  background-color: #3B3B3B;
  .block-block {
    margin: 25px;
    text-align: center;

    p {
      margin-bottom: 0;
    }

    .footer-banner {
      color: #fff;
      font-size: 28px;
      line-height: 1.2;

      &:hover {
        text-decoration: none;
      }

      img {
        height: 28px;
        margin-left: 15px;
      }
    }
  }
}
