// Page: User
// -----------------------------------------------------------------------------
.page--user {

  .region__main {

    h1#page-title {
      padding-bottom: 2rem;
      border-bottom: 3px solid color(blue-link);
    }

    .block {
      padding: 0 3rem;

      @include breakpoint(md) {
        padding: 0 5rem;
      }
    }
  }
}
