// Block: Regional contacts
// -----------------------------------------------------------------------------
.block-views-regional-contacts {
  border: 0;
  margin-bottom: 6rem;

  h2 {
    @extend h1.large;
    margin-bottom: 4rem;
  }
}
