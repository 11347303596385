.section {
  padding: 20px 0;
}
.column__content {
  margin-top: 20px;
}
.two_column,
.narrow_columns {
  .paragraphs-items-field-content-pr {
    display: flex;
    flex-wrap: wrap;
    grid-template-columns: 49% 49%;
    grid-row-gap: 2rem;
    grid-column-gap: 2%;
    justify-content: center;

    @include breakpoint(lg) {
      flex-wrap: nowrap;
      grid-template-columns: 49% 49%;
    }

     @include breakpoint(md) {
      flex-wrap: wrap;
      grid-template-columns: 49% 49%;
    }
  }

}


.two_column {
    .paragraphs-items-field-content-pr {
      grid-column-gap: 4%;
    }
}

.narrow_columns {
  padding: 0 80px;
    @include breakpoint(md) {
       padding: 0 20px;
    }
}

.section.cyan {
  padding: 30px 0;
  background:rgba($color: #0270CD, $alpha: 0.1);
}

.section.gray {
   background-color: #3B3B3B;
   color: #fff;
}
