// Color helpers.
// -----------------------------------------------------------------------------
@each $name, $color in $colors {
  .bgc-#{$name} {
    background-color: $color;
  }

  .tc-#{$name} {
    color: $color;
  }
}

// Space Helpers
// -----------------------------------------------------------------------------
@for $i from 0 through 10 {
  @each $abbrev, $prop in (t: top, r: right, b: bottom, l: left) {
    .m#{$abbrev}-#{$i} {
      margin-#{$prop}: $i + 0rem;
    }

    @each $breakpoint in (xs, sm, md, lg) {
      .m#{$abbrev}-#{$breakpoint}-#{$i} {
        @include breakpoint($breakpoint) {
          margin-#{$prop}: $i + 0rem;
        }
      }
    }

    .p#{$abbrev}-#{$i} {
      padding-#{$prop}: $i + 0rem;
    }
  }
}

// Font weight helpers
// -----------------------------------------------------------------------------
@each $abbrev, $weight in (light: 300, regular: 400, semi-bold: 600, bold: 700, extra-bold: 800) {
  .fw-#{$abbrev} {
    font-weight: $weight;
  }
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

// Two column text
// -----------------------------------------------------------------------------
.two-column-wrapper {
  margin: 0 15px;

  @include breakpoint(md) {
    .row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;

      &:first-child {
        .column {
          padding-right: 25px;
        }
      }
    }

    .column {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
  }
}
