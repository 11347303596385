// Node: Location
// -----------------------------------------------------------------------------

// Teaser
// -----------------------------------------------------------------------------
.node-location.node-teaser {
  font-size: 1.5rem;
  margin-bottom: 2rem;

  @include breakpoint(sm) {
    max-width: 210px;
  }

  @include breakpoint(md) {
    margin-bottom: 0;
  }

  h3 {
    color: color(green-dark);
    font-weight: 600;

    @include breakpoint(sm) {
      //height: 60px;
    }
  }

  .field-subtitle {
    font-weight: 400;
  }

  p {
    font-size: 1.5rem
  }

  .field-contact-link {
    margin: 3rem 0;
  }

  .field-directions-link {
    margin-top: 1rem;

    a {

      &:hover {
        text-decoration: none;
        color: color(green-link);
      }

      &:after {
        background: color(blue-link);
        border-radius: 100%;
        color: #fff;
        content: '\f0a9';
        font-family: FontAwesome;
        font-size: 15px;
        margin-left: 10px;
        padding: 2px 3px;
      }
    }
  }
}
