.paragraphs-item-text {
  line-height: 25px;
  text-align: left;

  p {
    font-size: 14px;
  }
}

.paragraphs-items-field-content-item {
  left: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  position: relative;
  right: 50%;
  width: 100vw;
}

.paragraphs-item-image-with-text {
  max-width: 365px;
  text-align: center;
  border: 2px solid #0270CD;
  margin-bottom: 30px;
  display: flex;

  @include breakpoint(md) {
    margin-bottom: 0;
  }

  .field-heading {
    position: relative;
    padding: 0 30px;
    margin-bottom: 25px;
    margin-bottom: 15px;
    min-height: 160px;

    h3 {
      line-height: 27px;
    }

    h3:after {
      content: '';
      border-bottom: 4px solid #0270CD;
      position: relative;
      width: 30%;
      display: block;
      margin: 0 auto;
      top: 8px;
    }

    p {
      padding-top: 15px;
    }
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    h4 {
      padding: 0;
      margin: 0;
      font-size: 14px;
    }

  }

  .field-image-pr {
    padding-top: 20px;
  }

  .field-text-pr {
    padding: 20px;
    font-size: 14px;
    line-height: 25px;
    min-height: 230px;

    p {
      font-size: 14px;
      line-height: 25px;
    }

    background:rgba($color: #0270CD, $alpha: 0.1);
  }
}


.paragraphs-item-cta {
  .field-text-pr {

    p {
      color: #fff;
      font-size: 28px;
      line-height: 25px;
      text-align: center;
    }

  }
}

.link-icon {
  position: relative;
  font-size: 15px;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
  &::after {
    content:'\f18e';
    font-family: FontAwesome;
    color: color(green-shadow);
    font-size: 20px;
    margin-left: 5px;
    margin-top: 2px;
  }
}

.link__cta {
  position: relative;
  a {
    &:hover {
      text-decoration: none;
    }
    p:after {
      content:'\f18e';
      font-family: FontAwesome;
      color: color(green-shadow);
      font-size: 30px;
      margin-left: 5px;
      margin-top: 2px;
    }
  }
}
