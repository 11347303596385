// Block: Main menu
// -----------------------------------------------------------------------------
.nav--main-menu {
  padding: 0;
  
  @include breakpoint(md) {
    float: left;
  }

  ul.nice-menu {
    margin: 0 -15px;
    float: none;
    
    @include breakpoint(sm) {
      margin: 0;
    }

    > li {
      float: none;
      border-bottom: 1px solid color(gray-border);

      @include breakpoint(sm) {
        float: left;
        border: 0;
      }

      &.menu-path-user-login {
        display: none;
      }

      > a {
        font-size: 1.7rem;
        font-weight: 600;
        padding: 14px 15px;

        @include breakpoint(sm) {
          font-weight: 300;
        }
      }

      &.over {

        > a {
          color: color(green-link);
        }
      }

      a {

        &.active,
        &:hover {
          color: color(green-link);
        }
      }

      ul {
        box-shadow: 0 4px 4px 0 rgba(color(black), .5);
        margin-top: -9px;
        border: 1px solid color(gray);

        &:before {
          display: none;
        }
      }
    }

    li.menuparent {

      ul {

        li.menuparent {
          position: relative;

          > a {
            display: block;
          }

          > i {
            color:  color(gray-dark);
            position: absolute;
            right: 15px;
            top: 12px;
            font-size: 1.2rem;
          }

          ul {
            border: 1px solid #ddd;
            left: 100%;
            top: 8px;
            box-shadow: none;
          }
        }
      }
    }
  }
}

.mean-container {

  a.meanmenu-reveal {
    display: none;
  }

  .mean-bar {
    background: none;
    padding: 0;
    border-top: 1px solid color(gray-border);

    .mean-nav {
      margin: 0;
      background: none;

      > ul {
        display: block !important;
        margin: 0;
        padding: 0;

        > li {
          margin: 0;
          padding: 0;
          display: block !important;

          > a {
            border: 0;
            border-bottom: 1px solid color(gray-border);
          }

          a {
            color: color(blue-link);
            font-weight: 600;
            text-transform: none;

            &.mean-expand {
              border: 0 !important;
              border-left: 1px solid color(gray-border) !important;
              display: block;
              font-size: 3rem !important;
              color: color(blue-link);
              line-height: 31px;
              height: 31px;
              margin: 0;

              &:hover {
                background: lighten(color(gray-border), 10);
              }
            }
          }

          &.menu-path-user-login {

            > a {
              display: block;

              &:before {
                @include fa(user);
                margin-right: 8px;
                font-size: 1.8rem;
              }
            }
          }
        }

        ul {
          background: lighten(color(gray-border), 10);
          border-bottom: 1px solid color(gray-border);
          padding: 1rem 0;
          visibility: visible !important;
          position: static;
          overflow: hidden !important;

          li {
            margin: 0;
            padding: 0;

            a {
              padding: .75rem 10%;
              border: 0;
              background: lighten(color(gray-border), 10);

              &.mean-expand {
                border: 0 !important;
              }
            }

            ul {

              li {

                a {
                  padding-left: 60px;
                }
              }
            }
          }
        }
      }
    }
  }
}
