.page-events-webinars {
  overflow-x: hidden;

  main {
    padding-bottom: 0;
  }

  .view-blog-posts.view-display-id-page {
    padding-top: 50px;
  }

  .view-content, .pager {
    background-color: #f6f8fA;
    left: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    overflow-x: hidden;
    padding-top: 50px;
    position: relative;
    right: 50%;
    width: 100vw;
  }

  .pager {
    margin-bottom: 0;
    margin-top: 0;

    @include breakpoint(sm) {
      padding: 6rem 0 8rem;
    }
  }

  .date {
    border-bottom: none;
  }
}
