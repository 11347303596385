// Page: Search
// -----------------------------------------------------------------------------
.page--search {

  .block--exposed-form--reports-search-box {
    padding: 0 3rem;

    @include breakpoint(md) {
      padding: 0 1rem 0 5rem;
    }

    .form-submit {
      @extend %button;
      background: color(blue-link);
      box-shadow: 0 3px 0 0 color(blue-shadow);
      border: 0;
      text-shadow: none;
      height: 30px;
      line-height: 30px;
      padding: 0 1.5rem;

      &:hover {
        background-color: color(blue-link);
        box-shadow: 0 1px 0 0 color(blue-shadow);
      }
    }
  }

  .block--advanced-search-link {
    padding: 0 3rem;
    margin-top: .5rem;

    @include breakpoint(md) {
      padding: 0;
    }
  }

  .view-search-reports {
    padding: 0;

    .view-header,
    .view-empty {
      border: 0;
      padding: 2rem 3rem;
      background: none;

      @include breakpoint(md) {
        padding: 2rem 5rem;
        border-bottom: 3px solid color(blue-link);
      }
    }

    .view-content {
      border: 0;
    }

    .views-row {
      padding-bottom: 7rem;
    }
  }
}

// Advanced
// -----------------------------------------------------------------------------
.page--search.page--advanced {

  .view-search-reports {
    max-width: 1000px;

    .row-container {
      background-color: color(gray-light);
      padding: 2rem;
    }

    .row {
      margin: 0 0 1rem;
      padding: 0 0 1rem;

      @include breakpoint(md) {
        border-bottom: 1px solid color(gray-border);
        margin: 0 0 3rem;
        padding: 0 0 3rem;

        &:last-child {
          border: 0;
          margin: 0;
          padding: 0;
        }
      }
    }

    .form-actions {

      .form-submit {
        @extend %button--blue;
        @extend %button--large;
      }
    }

    label {
      margin-bottom: 1rem;
    }
    
    .form-text {
      width: 100%;
      
      @include breakpoint(md) {
        width: auto;
      }
    }

    fieldset {
      border: 0;
      padding: 0 0 2rem;
      margin: 0 0 3rem;
      float: none;
      width: 100%;
      border-bottom: 1px solid color(gray-border);

      @include breakpoint(md) {
        float: left;
        width: 30%;
        border: 0;
        margin: 0;
        padding: 0;

        &#edit-f-field-industry {
          width: 40%;
        }
      }

      legend {
        float: none;
        display: block;
        font-size: 1.5rem;
        font-weight: 700;
        width: 100%;
        margin: 0 0 1rem;

        a {
          color: color(black);
        }

        .fieldset-legend {
          background: none;
          padding: 0;
        }
      }
    }

    .form-radio {
      margin: 0 .5rem 0 .5rem;
    }

    .form-item-f-0.form-type-radio,
    .form-type-checkbox {
      margin: 0 0 1rem;

      label {
        font-size: 1.4rem;
        font-weight: 600;
      }

      &.child {
        margin: 0;

        input {
          vertical-align: top;
          position: relative;
          top: 7px;
        }

        label {
          display: inline-block;
          font-weight: 300;
          width: 180px;
          margin: 0;
        }
      }
    }
  }
}

.region__sidebar-first #chemsys-search-block-view-report-filter-form {

  .form-type-radio {
    margin-bottom: 0;
    margin-top: .5rem;

    input {
      vertical-align: top;
      position: relative;
      top: 7px;
    }

    label {
      display: inline-block;
      width: 175px;
      font-size: 1.4rem;
    }
  }

  .form-item-f-0 {
    margin-top: 1rem;

    label {
      font-weight: 600;
    }

    &.child {
      margin: 0;

      label {
        width: 150px;
        font-weight: 300;
      }
    }
  }
}
