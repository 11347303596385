// Button
// -----------------------------------------------------------------------------
%button {
  font-weight: 600;
  color: color(white);
  border-radius: 2px;
  font-size: 14px;
  color: #FFFFFF;
  letter-spacing: 0;
  text-align: center;
  line-height: 31px;
  padding: 1rem 1.5rem;
  border: 0;
  text-shadow: none;
  font-family: 'Open Sans', sans-serif;
  line-height: 1;

  &:hover {
    position: relative;
    top: 2px;
    text-decoration: none;
  }

  i {
    margin-right: 1rem;
  }
}

%button--blue {
  @extend %button;
  background: color(blue-link);
  box-shadow: 0 3px 0 0 color(blue-shadow);

  &:hover {
    background-color: color(blue-link);
    box-shadow: 0 1px 0 0 color(blue-shadow);
  }
}

%button--green {
  @extend %button;
  background: color(green-link);
  box-shadow: 0 3px 0 0 color(green-shadow);

  &:hover {
    background-color: color(green-link);
    box-shadow: 0 1px 0 0 color(green-shadow);
  }
}

%button--orange {
  @extend %button;
  background: color(orange-link);
  box-shadow: 0 3px 0 0 color(orange);

  &:hover {
    background-color: color(orange-link);
    box-shadow: 0 1px 0 0 color(orange);
  }
}

%button--large {
  padding: 2rem 3rem;
  font-size: 2rem;
}

a.button-green {
  @extend %button--green;
}

a.button-blue {
  @extend %button--blue;
}

a.button-orange {
  @extend %button--orange;
}
