#block-block-24 {
  bottom: 6px;
  display: none;
  position: absolute;
  right: 455px;

  @media (min-width: 1380px) {
    display: block;
  }
}
