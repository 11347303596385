// Region: Footer
// -----------------------------------------------------------------------------
.region__footer {
  color: color(white);
  background-color: color(blue-link);
  padding: 3rem 0;

  @include breakpoint(md) {
    padding: 6rem 0 4rem;
  }

  a {
    color: color(white);
  }

  .block-panels-mini-footer-menus {

    @include breakpoint(md) {
      float: right;
    }
  }

  .block-panels-mini-footer {

    @include breakpoint(md) {
      float: left;
    }
  }
}
