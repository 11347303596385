// View: Blog posts
// -----------------------------------------------------------------------------
.view-blog-posts.view-display-id-page {
  position: relative;

  .view-empty {
    text-align: center;
    color: color(gray-dark);
    padding: 3rem 0;
  }

  .view-filters {
    margin-bottom: 2rem;

    @include breakpoint(sm) {
      margin-bottom: 0;
      position: absolute;
      top: -75px;
      right: 0;
    }

    @include breakpoint(lg) {
      right: 80px;
    }
  }

  label,
  .description {
    display: none;
  }

  .views-exposed-form {
    position: relative;
  }

  .views-widget {
    position: relative;
  }

  input[type="text"] {
    background-color: #e1e1e1;
    border: 0;
    border-radius: 10px;
    font-size: 14px;
    height: 27px;
    width: 195px;
    padding: 1px 36px 2px 12px;
  }

  .form-type-textfield {
    &:after {
      color: #0070cd;
      content: '\f002';
      font-family: 'FontAwesome';
      position: absolute;
      right: 15px;
    }
  }

  .views-exposed-widget {
    padding: 0;
    float: left;

    + .views-exposed-widget {
      margin-top: 1rem;

      @include breakpoint(sm) {
        margin-top: 0;
        margin-left: 2rem;
      }
    }

    &.views-widget-filter-title {

      input {
        padding-right: 50px;
      }
    }

    .form-type-select {
      position: relative;

      &:after {
        color: color(blue-link);
        content: "\f107";
        font-family: 'FontAwesome';
        font-size: 24px;
        font-weight: normal;
        position: absolute;
        right: 15px;
        top: 7px;
        z-index: 10;
      }

      select {
        -moz-appearance: none;
        -webkit-appearance: none;
        background: none;
        z-index: 100;
        position: relative;
      }
    }
  }

  .views-submit-button {
    margin-top: 1rem;

    @include breakpoint(sm) {
      margin: 0 0 0 -42px !important;
      width: auto;
    }
  }
}

// Other posts
// -----------------------------------------------------------------------------
.view-blog-posts.view-display-id-other_posts {

  .views-row {
    margin-bottom: 1.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid color(gray-border);
  }
}
