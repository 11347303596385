// Block: Nexant Subscriptions
// -----------------------------------------------------------------------------
.block-block-nexant-subscriptions,
.block-block-nexant-energy-and-chemicals-advisory {
  margin: 0 auto 5rem;
  max-width: 790px;

  @include breakpoint(sm) {
    margin-bottom: 10rem;
  }

  h2 {
    @extend h2.lead-center;
  }

  p {
    margin-top: 3.5rem;
  }
}
