// Node: Report
// -----------------------------------------------------------------------------
%badge {
  background-color: color(black);
  color: color(white);
  text-transform: uppercase;
  font-size: 1.1rem;
  font-weight: 700;
  text-align: center;
  display: block;
  line-height: 120%;
  padding: .4rem .25rem;
}

.node-report {

  .report__meta {
    padding: 0 0 .5rem;
    margin: 0 0 1rem;
    border-bottom: 1px solid color(gray-border);
    display: block;

    > div {
      display: inline-block;
      font-size: 1.4rem;
      font-weight: 300;

      &.field-industry {
        margin-right: 1rem;
      }
    }
  }

  .report__details,
  .report__links {
    width: 45%;
    float: left;
    
    @include breakpoint(sm) {
      width: auto;
    }
  }

  .report__links {
    margin-left: 5%;
    
    @include breakpoint(sm) {
      margin-left: 0;
    }
  }

  .report__details {
    
    @include breakpoint(sm) {
      width: 150px;
      float: left;
    }
  }

  .report__content {
    margin-bottom: 5rem;

    @include breakpoint(sm) {
      margin-left: 200px;
      margin-bottom: 0;
    }

    h1 {
      font-size: 2.8rem;
      line-height: 120%;
      font-weight: 300;
      margin: 0 0 2rem;
    }

    .field-body {
      font-weight: 500;
    }
  }

  .report__related-links {
    margin-top: 6rem;

    h3 {
      color: color(blue-link);
      border-bottom: 1px solid color(gray-border);
      padding: 0 0 .5rem;
      margin: 0 0 1rem;
    }

    .field-links {
      margin-top: .75rem;
      padding-left: 2rem;
      position: relative;

      &:before {
        content: " ● ";
        color: color(gray-dark);
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  .field-report-icon {
    margin-bottom: .5rem;

    img {
      width: 100%;
      height: auto;
    }
  }

  .field-program-tag,
  .field-report-program,
  .field-report-owned,
  .field-report-price,
  .field-program-capabilities span {
    @extend %badge;
    margin-bottom: .5rem;
  }

  .field-program-tag,
  .field-report-program {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .field-program-tag {

    &--markets-and-profitability {
      background-color: color(green-link);
    }

    &--technology-and-costs {
      background-color: color(orange);
    }

    &--special-reports {
      background-color: color(gray);
      color: color(black);
    }
  }

  .field-program-capabilities span {
    background-color: color(blue-link);

    &.market {
      background-color: color(blue-market);
    }
  }

  .field-report-owned {
    background-color: color(black);
  }

  .field-report-price {
    background-color: color(white);
    color: color(black);
    border: 1px solid color(black);
  }

  .report__links {
    margin-top: 2rem;

    @include breakpoint(md) {
      width: 160px;
    }

    .field-label {
      font-weight: 600;
      border-bottom: 1px solid color(gray-border);
      padding: 0 0 .5rem;
      margin-bottom: .5rem;
    }

    a {
      font-size: 1.5rem;
      font-weight: 300;
      text-decoration: none;
    }

    .file {
      display: block;
      padding-left: 30px;
      margin-bottom: .5rem;
      background: url("../images/icon-file.png") no-repeat 5px 4px;

      &-icon,
      &-size {
        display: none;
      }
    }

    .field-attachment-report {

      .file {
        background: url("../images/icon-report.png") no-repeat 5px 4px;
      }
    }
  }

  .report__buttons {
    margin-top: 6rem;
    
    a + a {
      
      @include breakpoint(md) {
        margin-left: 3rem;
      }
    }
  }
}

// Teaser
// -----------------------------------------------------------------------------
.node-report.node-teaser {

  .report__content {

    @include breakpoint(sm) {
      margin-right: 200px;
    }
  }

  .report__links {
    margin-top: 0;

    @include breakpoint(sm) {
      float: right;
    }
  }
}
