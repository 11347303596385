// Page: Contact
// -----------------------------------------------------------------------------
.page-node-5 {
  .block-views-locations-block-list {
    border-top: none;
    padding: 0;
  }

  main {
    padding-top: 3rem !important;

    @include breakpoint(sm) {
      padding-top: 6rem !important;
    }
  }
}

.page--contact,
.page--program-contacts {

  #page-title {
    @extend h1.large;
  }

  .region__main-prefix {
    padding-top: 3rem;
    padding-bottom: 0;
  }

  main {
    padding: 0;
  }
}

.page--regional-contacts {

  .region__main-prefix {
    padding-bottom: 0;
  }

  .region__main-suffix {
    padding-top: 0;
  }

  #page-title,
  .region__main-prefix {
    display: none;
  }

  #block-block-17,
  #block-chemsys-hubspot-chemsys-hubspot-contact-form {
    h2 {
      font-size: 48px;
      font-weight: 300;
      margin-bottom: 19px;
    }

    .hbspt-form,
    #chemsys-hubspot-contact-form {
      border: 2px solid color(blue-link);
      padding: 15px;
      margin-bottom: 25px;
    }

    iframe {
      width: 100% !important;
    }

    #chemsys-hubspot-contact-form {
      font-size: 0.95em;

      input[type=text],
      input[type=email],
      textarea {
        width: 100%;
        background-color: #f5f8fa;
        border: 1px solid #cbd6e2;
        color: #33475b;
        font-size: 1em;
        font-weight: 500;
      }

      label {
        color: #33475b;
        font-weight: normal;
      }

      input[type=submit] {
        background: #0770CD;
        border: 1px solid #0770CD;
        color: #FFF;
        border-radius: unset;
        font-size: 1em;
        font-weight: normal;
        text-shadow: none;
        line-height: 2em;
        margin: 1em 0 auto;
      }

      .form-required {
        display: inline;
      }
    }
  }

  .view-program-pages {
    padding: 0;
  }

  .block-views-locations-block-list {
    //border-top: 3px #1f6dcb solid;
  }

  .view-locations,
  .view-program-pages {
    h1 {
      border-bottom: 3px color(blue-link) solid;
      font-size: 48px;
      padding-bottom: 20px;
    }
  }

  .block-views-program-pages-program-contacts,
  .view-display-id-training_contacts {
    .program-contacts {
      @include breakpoint(sm) {
        max-width: 235px;
      }
    }

    h3 {
      color: color(green-dark);
      font-size: 24px;
      font-weight: 600;

      @include breakpoint(sm) {
        height: 60px;
      }
    }

    p {
      font-size: 1.5rem
    }
  }
}

.media-contact,
.follow-us {
  margin-bottom: 15px;

  span {
    color: color(blue-link);
    font-size: 28px;
    margin-right: 15px;
  }

  a {
    background-color: color(blue-link);
    border-radius: 50%;
    bottom: 4px;
    color: color(white);
    display: inline-block;
    height: 30px;
    line-height: 30px;
    position: relative;
    text-align: center;
    width: 30px;

    &:hover {
      background-color: color(green-link);
      color: color(white);
    }

    + a {
      margin-left: 5px;
    }

    i {
      display: inline-block;
      font-size: 1.8rem;
    }
  }
}
