#block-block-16 {
  bottom: 6px;
  display: none;
  position: absolute;
  right: 15px;

  @include breakpoint(md) {
    display: block;
  }
}
