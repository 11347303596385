.node-type-page {
  .page {
    overflow-x: hidden;
  }

  .region__main-prefix {
    padding: 1rem 0;

    &.bg-green {
      background: #78BE20;
    }

    &.bg-blue {
      background: #0072CE;
    }
  }

  .bg-blue,
  .bg-green {
    #page-title {
      color: #fff;
    }
  }

  #page-title {
    font-size: 36px;
    font-weight: 400;
    line-height: 43px;
    margin: 0;
  }

  .field-banner {
    left: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    position: relative;
    right: 50%;
    width: 100vw;

    img {
      width: 100%;
    }
  }

  .field-body {
    padding-top: 3rem;
  }
}

.page--locations {
  .page {
    overflow-x: auto;
  }
}

.page--subscriptions-and-reports-sustainability {
  main {
    padding-bottom: 0;
  }
  .block-bean {
    padding-bottom: 0;
  }
  .columns__paragraphs_item_7 {
    .paragraphs-items {
      display: block;
    }
  }
}
