// Component: Pager
// -----------------------------------------------------------------------------
ul.pager {
  text-align: center;

  li {
    display: inline-block;
    padding: 0 8px;
    cursor: pointer;

    &:hover {
      background-color: color(gray-border);
    }

    a {
      font-size: 1.3rem;
      padding: 0 3px;

      &:hover {
        text-decoration: none;
      }
    }

    &.pager-current {
      padding: 0 8px;
      background-color: color(blue-link);

      a {
        color: color(white);
      }
    }

    &.pager-next,
    &.pager-previous {
      background-color: color(gray-border);

      &:hover {
        background-color: color(blue-link);

        a {
          color: color(white);
        }
      }

      a {
        color: color(blue-link);
        text-transform: lowercase;
        padding: 0;
      }

      i {
        display: inline-block;
      }
    }

    &.pager-previous {

      i {
        margin-right: .5rem;
      }
    }

    &.pager-next {

      i {
        margin-left: .5rem;
      }
    }
  }
}
