#block-block-19 {
  bottom: 6px;
  display: none;
  position: absolute;
  right: 195px;

  @include breakpoint(lg) {
    display: block;
  }
}
