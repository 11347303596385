.node-type-news-article {
  h3 {
    color: #0070cd;
  }

  .region__sidebar-content {
    margin-top: 2rem;

    .block {
      > h3 {
        border-bottom: none;
      }
    }

    .location-date {
      padding-bottom: 15px;
      border-bottom: 1px solid #dddddd;
    }
  }

  .block-views-news-and-media-block-1 {
    h2 {
      font-size: 24px;
      margin-bottom: 50px;
    }

    .news-media-content {
      min-height: 500px;
      position: relative;
    }

    .view-content {
      overflow: hidden;

      .views-field-view-node {
        padding-bottom: 25px;

        @include breakpoint(sm) {
          position: absolute;
          bottom: 25px;
        }
      }
    }

    .field-news-date,
    .views-field-title,
    .views-field-view-node {
      padding: 15px 0;
    }

    .views-field-title {
      font-size: 24px;
      font-weight: 400;
      line-height: 34px;
    }

    .field-news-date {
      border-bottom: 1px solid #dddddd;
      width: 100%;
    }

    .button-blue {
      display: inline-block;
    }
  }

  .block-views-news-and-media-block-2 {
    display: none;
    @include breakpoint(md) {
      display: block;
    }
  }

  .view-display-id-block_2 {
    .views-row {
      border-bottom: 1px solid #dddddd;
      display: inline-block;
      margin-bottom: 15px;
      padding-bottom: 10px;
      width: 100%;
    }
  }

  .eqh {
    .container {
      width: 100%;
    }
  }

  .news-media-sidebar {
    clear: both;

    .left {
      float: left;
      margin-bottom: 15px;
      margin-right: 15px;

      img {
        width: 128px;
      }
    }

    .right {
      display: table;
      font-size: 13px;

      @include breakpoint(lg) {
        display: block;
      }

      .news-media-link {
        position: relative;

        .fa {
          color: #0070cd;
          font-size: 18px;
          position: absolute;
          right: -20px;
          top: 50%;
        }
      }

      .field-news-date {
        color: #0070cd;
        font-weight: 400;
      }
    }
  }

  .field-location,
  .field-news-date {
    display: inline-block;
  }

  .field-location {
    font-weight: bold;

    &:after {
      content: ':';
      position: relative;
      right: 4px;
    }
  }
}

.node-news-article {
  h2 {
    font-weight: 100;
    margin-bottom: 50px;
  }

  .location-date {
    border-bottom: 1px solid #dddddd;
    padding-bottom: 5px;
  }

  .field-image {
    margin-bottom: 25px;
  }
}

.back-button {
  bottom: 40px;
  padding-bottom: 50px;
  position: relative;
}
