@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,600i,700');

body {
  color: color(black);
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
  line-height: 24px;
}

a {
  color: color(blue-link);
}

h1 {
  color: color(blue-link);
  font-size: 4rem;
  font-weight: 300;

  &#page-title {
    margin: 0 0 2rem;
  }

  &.large {
    border-bottom: 2px solid color(blue-link);
    margin: 0 0 1rem;
    padding: 0 0 2rem;
    font-size: 4rem;
    font-weight: 300;
  }

  &.title {
    font-size: 4.8rem;
  }
}

h2 {
  color: color(blue-dark);
  font-size: 3rem;
  line-height: 120%;
  font-weight: 400;

  @include breakpoint(sm) {
    font-size: 3.6rem;
  }

  &.lead-center {
    border-bottom: 3px solid color(blue-dark);
    display: table;
    padding: 2.5rem 1rem;
    margin: 0 auto;

    @include breakpoint(sm) {
      padding: 2.5rem 5rem;
    }

    + p {
      margin-top: 3.5rem;
    }
  }

  &.bold {
    font-weight: 700;
  }

  &.underlined {
    font-weight: 600;
    border-bottom: 1px solid color(gray-border);
    padding: 0 0 .5rem;
    margin: 0 0 2rem;
    font-size: 2.8rem;
  }
}

h3 {
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 140%;
  color: color(black);

  &.small {
    font-size: 2rem;
  }

  &.bold {
    font-weight: 700;
    color: color(blue-link);
    font-size: 2.8rem;
  }

  &.underlined {
    font-weight: 600;
    border-bottom: 1px solid color(gray-border);
    padding: 0 0 .5rem;
    margin: 0 0 2rem;
    font-size: 2.8rem;
  }
}

h4 {
  font-size: 1.8rem;
  font-weight: 600;
  color: color(blue-link);
  margin: 3rem 0 1rem;
}

.large {
  font-size: 1.8rem;
}

small,
.small {
  font-size: 1.3rem;
}

.underlined {
  border-bottom: 1px solid color(gray-border);
  padding: 0 0 .5rem;
  margin: 0 0 2rem;
}

.light {
  font-weight: 300;
}

hr {
  border-top: 3px solid color(blue-link);
}
