// Page: Search data
// -----------------------------------------------------------------------------
.page-search-data {

  main {

    h1#page-title {
      border: 0;
    }

    .chemsys-simulator-wizard {
      padding: 2rem 5rem;
      border-top: 3px solid color(blue-link);

      h2 {
        font-size: 2.8rem;
      }

      .form-submit {
        @extend %button--blue;
        @extend %button--large;
      }
    }
  }
}

.page--database {

  .region__main {

    h1#page-title {
      padding-bottom: 2rem;
      border-bottom: 3px solid color(blue-link);
    }

    .block {
      padding: 0 3rem;

      @include breakpoint(md) {
        padding: 0 5rem;
      }
    }

    .chemsys-simulator-wizard {
      border-top: 0;
    }

    .block-chemsys-simulator {
      padding-bottom: 2rem;
    }

    .block-chemsys_simulator-refine-search {
      padding-bottom: 0;

      > header {

        a,
        h2 {
          color: color(white);
        }
      }
    }

    .view-footer {

      .download-links {
        padding: 2rem 0;
        top: 0;
      }
    }
  }

  &.page-database-table {

    .region__main {

      h1#page-title {
        border: 0;
        padding-bottom: 0;
      }

      table.view-grouping {

        .shrink {
          width: auto;
        }
      }

    }
  }
}

