// Block: Footer menus
// -----------------------------------------------------------------------------
.block-panels_mini-footer-menus {
  padding: 0;
  margin: 0 0 50px 0;
  border: 0;

  @include breakpoint(md) {
    width: 65%;
  }

  .panels-flexible-region {
    float: none;
    width: 100%;

    @include breakpoint(sm) {
      float: left;
      width: 22%;
    }

    @include breakpoint(md) {
      width: 14%;
    }

    + .panels-flexible-region {
      margin-top: 2rem;

      @include breakpoint(sm) {
        margin-top: 0;
        margin-left: 2.5rem;
      }

      @include breakpoint(md) {
        margin-left: 1rem;
      }

      @include breakpoint(lg) {
        margin-left: 3rem;
      }
    }

    &.panels-flexible-region-1-left,
    &.panels-flexible-region-new-left,
    &.panels-flexible-region-1-center,
    &.panels-flexible-region-new-center,
    &.panels-flexible-region-12-left,
    &.panels-flexible-region-12-center {

      @include breakpoint(md) {
        width: 30%;
      }
    }

    .inside {
      padding: 0;
    }

    &.panels-flexible-region-first {

      @include breakpoint(md) {
        max-width: 200px;
      }
    }
  }

  h2.pane-title {

    a {
      font-size: 1.5rem;
      font-weight: bold;

      &:hover {
        color: color(white);
        text-decoration: none;
      }
    }
  }

  ul.menu {

    a {
      color: color(white);
      font-size: 1.3rem;

      &:hover {
        color: color(white);
        text-decoration: none;
      }
    }
  }
}
