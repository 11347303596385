// Node: Industry
// -----------------------------------------------------------------------------
.node-type-industry {

  main {
    padding: 0;

    > .container {
      padding: 0;
      width: 100%;
    }
  }
}

.node-industry.node-full {
  background: color(gray-light);
  overflow-x: hidden;

  .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }

  @include breakpoint(md) {
    padding-bottom: 0;
  }

  h1 {
    background: #0072CE;
    color: #fff;
    font-weight: 400;
    line-height: 1.2;
    padding: 2rem 1.5rem;
    margin: 0;
  }

  .industry-header__container {
    position: relative;
    @media only screen and (max-width: 1200px) {
      .container-fluid {
        max-width: unset;
      }
    }
  }

  .industry-header__text {
    background-color: #0072CE;
    color: #fff;
    opacity: 0.8;
    padding: 15px;

    @media only screen and (min-width: 1200px) {
      position: absolute;
      top: 0;
      width: 900px;
      padding: 50px;
    }
  }

  .field-thumbnail {
    background-color: #f6f8fA;
    left: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    position: relative;
    right: 50%;
    width: 100vw;

    img {
      width: 100%;
    }
  }
}
