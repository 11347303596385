// Block: Blog other posts
// -----------------------------------------------------------------------------
.block-views-blog-posts-other-posts {

  > h3 {
    border: 0 !important;

    @include breakpoint(md) {
      //margin-bottom: 6rem !important;
    }
  }
}
