// Region: Sidebar content
// -----------------------------------------------------------------------------
.region__sidebar-content {
  margin-top: 8rem;
  
  @include breakpoint(md) {
    margin-top: 0;
  }

  .block {

    + .block {
      margin-top: 4rem;
      
      @include breakpoint(md) {
        margin-top: 8rem;
      }
    }

    > h3 {
      color: color(blue-link);
      border-bottom: 1px solid color(gray-border);
      padding: 0 0 1rem;
      margin: 0 0 2rem;
    }
  }
}
