// Colors.
// -----------------------------------------------------------------------------
$colors: (
  white: #fff,
  black: #000,
  blue-dark: #0270cd,
  blue-link: #0070cd,
  blue-market: #002FA4,
  blue-shadow: #035EA9,
  gray-border: #ddd,
  gray-light: #F5F5F5,
  gray-bg: #D8D8D8,
  green-link: #82bb27,
  green-dark: #77bc1f,
  green-shadow: #5a9019,
  orange-link: #e2a459,
  orange: #ff8300,
  gray: #e5e5e5,
  gray-dark: #666,
);

// Media queries breakpoints
// ------------------------------------------------------------------------------------------------------

// Extra small screen / phone
// Note: Deprecated $screen-xs and $screen-phone as of v3.0.1
$screen-xs: 480px !default;
$screen-xs-min: $screen-xs !default;
$screen-phone: $screen-xs-min !default;

// Small screen / tablet
// Note: Deprecated $screen-sm and $screen-tablet as of v3.0.1
$screen-sm: 768px !default;
$screen-sm-min: $screen-sm !default;
$screen-tablet: $screen-sm-min !default;

// Medium screen / desktop
// Note: Deprecated $screen-md and $screen-desktop as of v3.0.1
$screen-md: 992px !default;
$screen-md-min: $screen-md !default;
$screen-desktop: $screen-md-min !default;

// Large screen / wide desktop
// Note: Deprecated $screen-lg and $screen-lg-desktop as of v3.0.1
$screen-lg: 1200px !default;
$screen-lg-min: $screen-lg !default;
$screen-lg-desktop: $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1) !default;
$screen-sm-max: ($screen-md-min - 1) !default;
$screen-md-max: ($screen-lg-min - 1) !default;
