.page-news-and-media {
  .page {
    overflow-x: hidden;
  }

  main {
    padding-bottom: 0;
  }

  .page-title {
    display: block;
    float: left;
  }

  .view-header {
    display: inline-block;
    @include breakpoint(md) {
      float: left;
    }
  }

  .view-filters {
    @include breakpoint(md) {
      bottom: -25px;
      display: inline-block;
      float: right;
      position: relative;
    }

    ::placeholder {
      color: #0070cd;
    }

    .views-widget {
      position: relative;
    }

    input[type="text"] {
      background-color: #e1e1e1;
      border: 0;
      border-radius: 10px;
      font-size: 14px;
      height: 27px;
      width: 195px;
      padding: 1px 36px 2px 12px;
    }

    .form-type-textfield {
      &:after {
        color: #0070cd;
        content: '\f002';
        font-family: 'FontAwesome';
        position: absolute;
        right: 15px;
      }
    }
  }

  .filter-results {
    font-weight: bold;
    padding: 15px;
  }

  .view-filters {
    clear: both;
    padding-bottom: 25px;
    @include breakpoint(md) {
      clear: none;
    }
  }

  .views-widget-filter-field_news_article_category_tid {
    label,
    .views-widget {
      display: inline-block;
      color: #0070cd;
    }

    label {
      font-weight: 200;
    }

    .chosen-container {
      position: relative;
      width: auto !important;
    }

    .chosen-with-drop {
      .chosen-drop {
        width: 200px;
      }
    }

    .chosen-single {
      background: transparent;
      border: none;
      border-radius: unset;
      box-shadow: unset;
      display: inline-block;
      font-weight: bold;
      color: #0070cd;

      span {
        bottom: -2px;
        position: relative;

        &:after {
          content: '\f107';
          font-family: 'FontAwesome';
          font-weight: bold;
          padding-left: 5px;
        }
      }

      b {
        background-image: none !important;
        position: relative;
        right: 70px;
      }
    }

    @include breakpoint(md) {
      .chosen-with-drop {
        .chosen-drop {
          left: -70px;
        }
      }
    }
  }

  .view-empty {
    padding-top: 50px;
  }

  .view-display-id-attachment_1 {
    .view-content {
      padding-top: 50px;
    }

    .views-field-view-node {
      bottom: 0;
      position: relative;
    }

    .follow-us {
      margin: 25px 0;
    }
  }

  .eqh {
    .view-content {
      overflow: hidden;

      .views-field-view-node {
        padding-bottom: 25px;

        @include breakpoint(sm) {
          position: absolute;
          bottom: 0;
        }
      }
    }
  }

  .bg-full {
    background-color: #f6f8fA;
    left: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    overflow-x: hidden;
    position: relative;
    right: 50%;
    width: 100vw;
  }

  .views-row {
    margin-bottom: 25px;
    min-height: unset;
  }

  .news-media-content {
    background: color(white);
    min-height: 500px;
    position: relative;
  }

  .field-news-date,
  .views-field-title,
  .views-field-view-node {
    padding: 15px;
  }

  .views-field-title {
    font-size: 24px;
    font-weight: 400;
    line-height: 34px;
  }

  .field-news-date {
    padding-bottom: 0;
  }

  .button-blue {
    display: inline-block;
  }
}

.view-display-id-page {
  .search-view-empty {
    height: 200px;
    padding-top: 25px;

    .container {
      padding: 0 15px;
    }
  }
}
