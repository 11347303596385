// Block: Front page programs
// -----------------------------------------------------------------------------
.block-views-programs,
.block-views-subscriptions-amp-reports,
.block-views-our-business-addressing-all-our-client-needs {
  margin-top: 5rem;

  @include breakpoint(sm) {
    margin-top: 10rem;
  }

  > h2 {
    text-align: center;
    font-weight: 600;
    margin-bottom: 6rem;
  }
}

.block-views-our-business-addressing-all-our-client-needs {
  > h2 {
    font-weight: 400;
  }
}

.block-block-nexanteca-the-energy-and-chemicals-advisory-company {
  h2 {
    text-align: center;
  }
}
