// Blog post
// -----------------------------------------------------------------------------
.node-type-blog-post {

  h3 {
    color: #0070cd;
  }

  .region__sidebar-content {

    @include breakpoint(md) {
      margin-top: 2rem;
    }

    .block-views-blog-posts-other-posts,
    .block-views-blog-posts-archive {

      @include breakpoint(sm) {
        width: 48%;
        float: left;
      }

      @include breakpoint(md) {
        float: none;
        width: 100%;
      }
    }

    .block-views-blog-posts-archive {

      @include breakpoint(sm) {
        margin-left: 10%;
        width: 42%;
      }

      @include breakpoint(md) {
        margin-left: 0;
        width: 100%;
      }
    }
  }
}

.node-blog-post,
.node-event-webinar {
  p {
    font-size: 1.5rem;
    line-height: 24px;
  }

  .field-image {
    margin-bottom: 2rem;

    @include breakpoint(md) {
      float: left;
      margin-right: 2.5rem;
    }
  }

  .date {
    border-bottom: 1px solid color(gray-border);
    display: block;
    padding: 0 0 .5rem;
    font-size: 1.5rem;
  }

  > h2 {
    font-size: 2.8rem;
    font-weight: 300;
    margin-bottom: 4rem;
  }

  .links {
    margin-top: 5rem;
  }
}

.node-event-webinar {
  .field-image {

    @include breakpoint(md) {
      float: unset;
      margin-right: 0;
    }
  }
}

.blog-post__related-links {

  .field-label {
    font-size: 1.8rem;
    font-weight: 600;
    color: color(blue-link);
    margin: 3rem 0 1rem;
  }

  .field-related-files {
    margin-top: .75rem;
  }

  .field-links {
    margin-top: .5rem;
    padding-left: 2rem;
    position: relative;

    &:before {
      content: " ● ";
      color: color(gray-dark);
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

// Teaser
// -----------------------------------------------------------------------------
.node-blog-post.node-teaser,
.node-event-webinar.node-teaser {
  background: #fff;
  padding-bottom: 2rem;
  margin-bottom: 3rem;

  span {
    padding: 0 15px;
  }

  .field-image {
    margin-bottom: 15px;
    float: none;
    margin-right: 0;

    a {
      display: block;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  h3 {
    font-weight: 600;
    color: color(blue-link);
    margin: 1rem 0 2rem;
    padding: 0 15px;

    @include breakpoint(sm) {
      height: 150px;
    }

    a {

      &:hover {
        text-decoration: none;
        color: color(green-link);
      }
    }
  }

  .button-blue {
    left: 15px;
    position: relative;
  }
}

// Teaser mini
// -----------------------------------------------------------------------------
.node-blog-post.node-teaser-mini {
  position: relative;
  max-width: 350px;

  a {
    font-weight: 300;
    line-height: 20px;
    display: inline-block;
    padding-right: 1rem;

    i {
      position: absolute;
      right: 2rem;
      top: 30%;
      color: color(blue-link);
      font-size: 2.2rem;

      @include breakpoint(md) {
        right: .8rem;
      }
    }
  }

  .date {
    border: 0;
    font-size: 1.3rem;
    color: color(blue-link);
    font-weight: 500;
  }

  .field-image {
    margin-bottom: 0;
    float: none;
    margin-right: 0;

    a {
      padding: 0;
    }
  }

  .col-md-8 {
    padding-left: .5rem;
  }
}
