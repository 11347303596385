.page-blog {
  overflow-x: hidden;

  .region__main-prefix {
    background: none;
  }

  main {
    background-color: #f6f8fA;
    left: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    position: relative;
    right: 50%;
    width: 100vw;
  }

  .view-blog-posts.view-display-id-page {
    padding-top: 50px;
  }

  .date {
    border-bottom: none;
  }
}
