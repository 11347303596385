// View: Related reports
// -----------------------------------------------------------------------------
.view-related-reports {

  .view-content {
    overflow: hidden;
    
    @include breakpoint(md) {
      display: flex;
      justify-content: space-around;
    }

    .col {
      margin-bottom: 5rem;
      position: relative;

      @include breakpoint(md) {
        width: 32%;
        padding-bottom: 70px;
      }

      @include breakpoint(lg) {
        width: 30%;
      }
    }
  }

  h4 {
    font-size: 2.2rem;
    color: color(black);
    border-bottom: 3px solid color(blue-link);
    margin: 2rem 0 1rem;
    padding: 0 1rem 1.5rem;
    font-weight: normal;
    
    @include breakpoint(md) {
      font-size: 1.8rem;
      padding: 0 0 1.5rem;
    }

    @include breakpoint(lg) {
      font-size: 2.2rem;
    }

    > div {
      display: inline-block;
    }

    img {
      width: 45px;
      vertical-align: middle;
      margin-right: 1rem;
    }
  }

  .views-row-wrapper {

    @include breakpoint(md) {
      height: 300px;
    }
  }

  .views-row {
    padding: 1rem 0;

    + .views-row {
      border-top: 1px solid color(gray-border);
    }
  }

  .views-field-title {

    a {
      font-size: 1.6rem;
      font-weight: 300;
      display: block;
      padding-right: 3rem;
      position: relative;
      min-height: 50px;
      line-height: 50px;

      span {
        line-height: 140%;
        display: inline-block;
      }

      i {
        position: absolute;
        right: 0;
        top: 30%;
        color: color(green-link);
        font-size: 2.2rem;
      }
    }
  }

  .views-field-nid {
    margin-top: 2rem;
    display: none;

    @include breakpoint(md) {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  .views-row {

    &:last-child {

      .views-field-nid {
        display: block;
      }
    }
  }

  .button-blue {
    display: block;
    padding: 2rem 0;
  }
}
