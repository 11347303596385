.with-sidebar_first {

  main {
    padding: 0;
    display: flex;
    flex-direction: column;

    @include breakpoint(md) {
      flex-direction: row;
    }

    .region__sidebar-first {
      background-color: color(gray-light);

      @include breakpoint(md) {
        flex: 0 0 250px;
        padding-bottom: 10rem;
      }

      .block {

        > h2 {
          background-color: color(gray-bg);
          margin: 0;
          color: color(black);
          font-size: 1.8rem;
          font-weight: 300;
          padding: 1.8rem 2rem;
        }

        form {
          padding: 1.8rem 2rem;

          fieldset.collapsible {
            border: 0;
            padding: 0 0 2rem;
            border-bottom: 1px solid color(gray-border);
            margin-bottom: 3rem;

            legend {
              float: none;
              display: block;
              font-size: 1.5rem;
              font-weight: 600;
              width: 100%;
              position: relative;
              font-weight: normal;

              a {
                color: color(black);
              }

              .summary {
                display: none;
              }

              .fieldset-title {
                display: inline-block;
                text-decoration: none;
                width: 100%;
                border: 0;

                &:after {
                  content: '-';
                  border: 1px solid color(gray-border);
                  width: 15px;
                  height: 15px;
                  display: inline-block;
                  position: absolute;
                  right: 0;
                  top: .5rem;
                  font-weight: 600;
                  background-color: color(white);
                  text-align: center;
                  line-height: 10px;
                  font-size: 2rem;
                }
              }

              .fieldset-legend {
                background: none;
                padding: 0;
              }
            }


            &.collapsed {
              height: auto;

              legend {

                .fieldset-title {

                  &:after {
                    content: '+';
                    line-height: 12px;
                    font-size: 1.5rem;
                  }
                }
              }
            }
          }

          .form-radio {
            margin: 0 .5rem 0 .5rem;
          }

          .form-type-radio {

            label {
              font-weight: 300;
            }
          }

          .form-actions {

            .form-submit {
              @extend %button;
              background: color(blue-link);
              box-shadow: 0 3px 0 0 color(blue-shadow);
              border: 0;
              text-shadow: none;
              height: 30px;
              line-height: 30px;
              padding: 0 1.5rem;

              &:hover {
                background-color: color(blue-link);
                box-shadow: 0 1px 0 0 color(blue-shadow);
              }
            }

            a {
              clear: both;
              display: block;
              margin-top: 1rem;
            }
          }
        }
      }

      .block-chemsys-simulator-primary {

        form {

          label.option {
            font-size: 1.4rem;
          }

          fieldset.collapsible {
            margin-bottom: 1rem;
            padding-bottom: 1rem;
          }

          select.form-select {
            padding: 2px 20px 2px 5px;
          }
        }
      }
    }

    .region__main {
      padding: 1.5rem 0;
      overflow: hidden;
      flex: auto;
    }

    h1#page-title {
      font-size: 4rem;
      margin: 0 0 2.5rem;
      color: color(black);
      padding: 0 3rem;

      @include breakpoint(md) {
        padding: 0 5rem;
      }
    }
  }
}
