// Region: Header
// -----------------------------------------------------------------------------
.region__header {
  padding: 1.8rem 0 1.5rem;
  position: relative;

  .not-front & {
    border-bottom: 1px solid color(gray-border);
  }

  @include breakpoint(sm) {
    padding: 1.8rem 0;
    border-bottom: 1px solid color(gray-border);
  }

  #logo {

    a {
      display: block;
      width: 150px;

      @include breakpoint(md) {
        width: 230px;
      }

      img {
        height: auto;
        width: 100%;
      }
    }
  }

  .header__toggle {
    position: absolute;
    right: 15px;
    top: -10px;

    @include breakpoint(sm) {
      display: none;
    }

    a {
      background-color: color(blue-dark);
      color: color(white);
      cursor: pointer;
      display: inline-block;
      font-size: 2.8rem;
      height: 45px;
      line-height: 45px;
      text-align: center;
      width: 45px;

      + a {
        margin-left: 1rem;
      }
    }
  }
}

.collapse {
  display: none;

  @include breakpoint(sm) {
    display: block !important;
  }
}

.header {
  background-color: lighten(color(gray-border), 10);
  float: none;
  margin: 1em -15px -18px;
  padding: 1.5rem;
  text-align: center;

  @include breakpoint(sm) {
    background: none;
    float: right;
    margin: 0;
    padding: 0;
    text-align: right;
    position: relative;
  }

  @include breakpoint(md) {
    margin: 1rem 0 0;
  }

  .block {
    display: inline-block;
    float: none;
    margin: 0;
    padding: 0;

    @include breakpoint(md) {
      margin: 0 0 0 1.5rem;
    }
  }

  .header--user-login {
    margin: 0;
    top: 0;
    display: none;

    @include breakpoint(sm) {
      display: inline-block;
    }

    .user-login {
      text-align: center;
    }
  }

  #block-block-20 {
    .active {
      color: #82bb27;
    }
  }

  .nav--user-menu--logged-in {
    display: none;
    z-index: 300;

    @include breakpoint(sm) {
      display: inline-block;
    }

    .menu {
      position: static;

      > li {

        > a {
          padding: 5px 12px;
        }
      }
    }
  }

  .block--header-advanced-search-link {
    display: none;

    @include breakpoint(sm) {
      display: inline-block;
    }

    p {
      margin-bottom: 0;
    }

    a {
      padding: 6px 12px;

      @include breakpoint(sm) {
        padding-left: 0;
      }
    }
  }

  .block-chemsys-search-search {
    width: 100%;

    @include breakpoint(sm) {
      width: 200px;
    }

    input[type=text] {
      color: color(black);
      height: 35px;
      width: 100%;

      @include breakpoint(sm) {
        height: 30px;
      }
    }

    input[type=submit] {
      top: 9px;

      @include breakpoint(sm) {
        top: 5px;
      }
    }
  }
}
