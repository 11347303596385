.page--internships,
.node-type-quote,
.page-node-46872,
.page-node-46876,
.page-node-46651 {
  .region__main-full {
    background: #f5f5f5;
    margin-bottom: 0;
  }

  .field-body {
    h2 {
      font-weight: 100;
    }
  }

  .quotes-slider {

    @include breakpoint(lg) {
      right: -50px;
      position: relative;
    }

    .quote-icon {
      img {
        margin: 0 auto;
      }
    }

    i {
      color: #82bb27;
      font-size: 36px;
    }

    .field-image {
      margin-bottom: 25px;

      img {
        margin: 0 auto;

        @include breakpoint(lg) {
          margin: unset;
        }
      }
    }

    .quote-container {
      @include breakpoint(lg) {
        width: 85%;
      }
    }

    .field-body {
      p {
        color: #0070cd;
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 15px;
      }
    }

    .quote-name {
      font-size: 14px;
      font-weight: 600;
    }

    .field-user-title {
      font-weight: 400;
    }

    hr {
      float: left;
      width: 55px;
    }

    .field-teaser {
      clear: both;

      p {
        font-style: italic;
      }
    }
  }
}

.page-node-46872 {
  main {
    padding-bottom: 0;
  }
  .region__main-suffix {
    padding-top: 0;
  }
}
