// Hero
// -----------------------------------------------------------------------------
.hero {

  > div {
    background: no-repeat center;
    background-size: cover;
  }

  .container {
    min-height: 225px;
    position: relative;

    @include breakpoint(sm) {
      min-height: 425px;
    }
  }

  &__caption {
    background-color: color(blue-dark);
    color: color(white);
    margin: 225px -15px 0;
    padding: 3rem 1.5rem;
    text-align: center;

    @include breakpoint(sm) {
      background-color: rgba(color(blue-dark), .8);
      bottom: 0;
      left: 15px;
      margin: 0;
      max-width: 450px;
      padding: 3.6rem 4.5rem;
      position: absolute;
      text-align: left;
    }
  }

  h4 {
    font-size: 2.4rem;
    line-height: 3rem;
    margin: 0;
    color: color(white);
    font-weight: 500;

    @include breakpoint(sm) {
      font-size: 3rem;
      line-height: 4.6rem;
    }

    + .field-caption {
      margin-top: 1rem;

      @include breakpoint(md) {
        margin-top: 2rem;
      }
    }
  }
}
