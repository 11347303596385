// Slick
// -----------------------------------------------------------------------------
.slick {

  &-dots {
    bottom: 15px;
    position: absolute;
    text-align: center;
    width: 100%;
    z-index: 100;
    margin: 0;
    display: none !important;
    
    @include breakpoint(sm) {
      display: block !important;
    }

    li {
      border: 1px solid color(white);
      border-radius: 50%;
      display: inline-block;
      height: 20px;
      width: 20px;
      margin: 0 5px;
      font-size: 0;
      cursor: pointer;
      position: relative;

      &:before {
        content: '';
        border: 2px solid transparent;
        border-radius: 50%;
        display: inline-block;
        height: 18px;
        width: 18px;
        top: 0;
        left: 0;
      }

      &:hover {
        background-color: color(white);
      }

      &.slick-active {
        background-color: color(green-dark);

        &:before {
          border-color: color(white);
        }
      }
    }
  }
}
