// Region: Sub header
// -----------------------------------------------------------------------------
.region__sub-header {
  border-bottom: 10px solid color(blue-dark);

  .container-fluid {
    position: relative;
  }

  .delimiter {
    &:after {
      content: '\f105';
      font-family: FontAwesome;
    }
  }
}
