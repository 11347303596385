// Block: User login
// -----------------------------------------------------------------------------
.header--user-login {

  .user-login {

    &:after {
      width: 100% !important;
    }
  }
}

#user-login-form {
  text-align: left;
}
