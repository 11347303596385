// Block: blog post archive
// -----------------------------------------------------------------------------
.block-views-blog-posts-archive {

  .item-list {

    ul {
      margin: 0;
      padding: 0;

      li {
        list-style: none outside none;
        margin: 0 0 2rem;
      }
    }
  }
}
