// View: Program pages
// -----------------------------------------------------------------------------

// Contacts
// -----------------------------------------------------------------------------
.view-display-id-program_contacts,
.view-display-id-training_contacts {
  font-size: 2rem;
  margin: 2rem 0;

  @include breakpoint(md) {
    padding: 0 3rem;
  }

  .col-md-4 {
    margin-bottom: 3rem;

    @include breakpoint(sm) {
      margin-bottom: 0;
    }

    @include breakpoint(md) {
      margin-bottom: 1rem;
    }
  }

  .col-sm-6 {

    @include breakpoint(sm) {
      height: 280px;
    }
  }

  p {
    font-size: 2rem;
    margin: 0 0 .25rem;
  }

  h3 {
    font-size: 2rem;
    line-height: 120%;
    font-weight: 600;

    @include breakpoint(sm) {
      font-size: 2.4rem;
    }

    @include breakpoint(md) {
      font-size: 2.8rem;
    }
  }

  .field-contact {
    a {
      @extend %button--green;
      margin-top: 2rem;
      display: inline-block;
      padding: 1.5rem 3rem;
    }
  }
}
