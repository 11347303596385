.block-bean {
  padding: 3rem 0;
}

.bean-block-cards {

  h2 {
    font-weight: 100;
  }

  .field-collection-container {
    border-bottom: transparent;
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-flow: row wrap;

    @include breakpoint(lg) {
      flex-direction: row;
    }

    .field-block-card {
      margin-bottom: 25px;
      text-align: center;
      width: 100%;

      @include breakpoint(md) {
        width: 50%;
      }

      @include breakpoint(lg) {
        width: 33%;
      }
    }
  }

  .field-collection-view {
    background: #fff;
    border: 2px solid #0270CD;

    .field-body {
      padding: 0 15px;
    }
  }

  .field-body {
    margin-bottom: 25px;
    padding-top: 0 !important;
  }

  .field-card-icon {
    margin: 15px 0 25px 0;

    img {
      height: 50px;
      width: auto;
    }
  }

  .field-card-title {
    font-size: 24px;
    font-weight: 400;
  }

  hr {
    margin-top: 0;
    width: 55px;
  }

  .field-link {
    margin-top: 35px;
    text-align: center;
  }
}

.block-bean-summer-internship-program {
  background-color: rgba(2, 112, 205, 0.04);
  margin: 0 -9999px;
  padding: 50px 9999px;
}
