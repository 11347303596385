// Block: Program Contacts
// -----------------------------------------------------------------------------
.block-views-program-contacts-block-list {
  margin: 4rem 0;
  font-size: 2rem;

  @include breakpoint(md) {
    padding: 0 3rem;
    margin: 8rem 0;
  }

  .row {

    @include breakpoint(md) {
      margin-bottom: 4rem;
    }
  }
  
  .col-md-4 {
    margin-bottom: 3rem;
    
    @include breakpoint(sm) {
      margin-bottom: 0;
    }
    
    @include breakpoint(md) {
      margin-bottom: 1rem;
    }
  }

  .col-sm-6 {

    @include breakpoint(sm) {
      height: 350px;
    }
    
    @include breakpoint(md) {
      height: 400px;
    }
  }

  p {
    font-size: 2rem;
    margin: 0 0 .25rem;
  }

  .contact-form {
    margin: 3rem 0;
  }

  h3 {
    font-size: 2rem;
    line-height: 120%;
    font-weight: 600;

    @include breakpoint(sm) {
      font-size: 2.4rem;
    }

    @include breakpoint(md) {
      font-size: 2.8rem;
    }
  }

  .button-green {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
