// Node: Program page
// -----------------------------------------------------------------------------
.node-type-program-page {

  main {
    padding: 0;

    > .container {
      padding: 0;
      width: 100%;
    }
  }

  .program__content {

    @include breakpoint(sm) {
      margin-left: 320px;
    }

    @include breakpoint(md) {
      margin-left: 400px;
    }
  }

  .program__sidebar {

    @include breakpoint(sm) {
      width: 200px;
      float: left;
    }
    
    @include breakpoint(sm) {
      width: 280px;
    }

    h3 {
      font-weight: 300;
    }

    div + h3 {
      margin-top: 2rem;

      @include breakpoint(sm) {
        margin-top: 4rem;
      }
    }

    .field-related-links {

      .file {

        .file-icon {
          display: none;
        }
      }
    }

    .menu-block {
      background-color: color(gray-light);
      margin-bottom: 3rem;

      @include breakpoint(md) {
        margin-bottom: 0;
      }

      h3 {
        padding: 1rem 0;
        margin: 0;
        background-color: color(white);
      }

      ul.menu {
        margin: 0;
        padding: 0;

        li {
          margin: 0;

          a {
            padding: 1rem 4rem;
            display: block;
            font-weight: 600;

            &:hover {
              background-color: color(blue-link);
              text-decoration: none;
              color: color(white);
            }

            &.active {
              background-color: color(white);
              color: color(blue-link);

              &:hover {
                color: color(blue-link);
              }
            }
          }

          ul {

            li {

              a {
                padding-left: 8rem;
                font-weight: normal;
              }
            }
          }
        }
      }
    }

    ul {
      margin: 0;
      padding: 0;

      li {
        margin: 0 0 1.5rem;
        padding: 0;
        list-style: none outside none;

        a {
          font-weight: 600;
        }

        ul {
          margin-top: 1.5rem;
          margin-left: 3rem;

          li {

            a {
              font-weight: normal;
            }
          }
        }
      }
    }
  }
}

// Full
// -----------------------------------------------------------------------------
.program__hero {
  background-color: color(blue-link);
  color: color(white);
  padding: 2rem 0 3rem;

  @include breakpoint(sm) {
    padding: 4rem 0 5rem;
  }

  @include breakpoint(md) {
    padding: 4rem 0 7rem;
  }

  .program__sidebar {
    margin-top: 0;
  }

  h1 {
    color: color(white);
    margin: 2rem 0 0;
    text-align: center;
    line-height: 140%;

    @include breakpoint(sm) {
      text-align: left;
    }

    @include breakpoint(md) {
      margin: 5rem 0 0;
    }
  }
}

.node-program-page.node-full {

  > .container {
    padding-top: 3rem;
    padding-bottom: 3rem;

    @include breakpoint(md) {
      padding-top: 10rem;
      padding-bottom: 10rem;
    }
  }

  .field-icon {
    text-align: center;
  }

  .program__content {

    h3 {
      color: color(blue-link);
    }

    .media {
      margin: 3rem 0;

      @include breakpoint(md) {
        margin: 5rem 0;
      }
    }
  }
}

// Teaser
// -----------------------------------------------------------------------------
.node-program-page.node-teaser,
.node-program-page.node-teaser-micro {
  border: 1px solid color(blue-link);
  padding: 4rem 3rem 5rem;
  margin-bottom: 4rem;

  @include breakpoint(sm) {
    padding: 6rem 3rem 5rem;
  }

  h3 {

    @include breakpoint(md) {
      height: 90px;
      line-height: 80px;
    }

    @include breakpoint(lg) {
      height: 60px;
      line-height: 60px;
    }

    span {
      line-height: 150%;
      display: inline-block;
    }
  }
}

// Teaser-micro
// -----------------------------------------------------------------------------
.node-program-page.node-teaser-micro {
  padding: 0 0 5rem;

  .field-teaser {
    padding: 0 3rem;
  }
}
