// Region: main
// -----------------------------------------------------------------------------
main {
  padding: 3rem 0;

  @include breakpoint(sm) {
    padding: 6rem 0 8rem;
  }
}

.region__main-prefix {
  padding: 3rem 0;

  @include breakpoint(sm) {
    padding: 8rem 0 4rem;
  }

  + main {
    padding-top: 0;
  }

  h1 {
    margin: 0;
  }
}

.region__main-suffix,
.region__main-full {
  padding: 3rem 0 6rem;
  
  @include breakpoint(md) {
    padding-bottom: 10rem;
  }
}

.region__main-full-suffix {
  padding-top: 3rem;
}
